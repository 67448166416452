<script setup lang="ts">
import type { Collections, ContentDeCollectionItem } from '@nuxt/content';

const { locale } = useI18n();
const route = useRoute();

const { data: page } = await useAsyncData<ContentDeCollectionItem>(route.path, async () => {
  const collection = ('content_' + locale.value) as keyof Collections;

  const result = await queryCollection(collection)
    .path(route.path)
    .first();

  return result as ContentDeCollectionItem;
});

// Page not found, set correct status code on SSR
if (!page.value && process.server) {
  const event = useRequestEvent();
  if (event) { event.node.res.statusCode = 404; }
}

useHead({
  title: page?.value?.title,
  meta: [
    { name: 'description', content: page?.value?.description },
  ],
});
</script>

<template>
  <NuxtLayout>
    <div v-if="page" class="container pb-6">
      <section class="section is-small container">
        <div class="has-text-centered">
          <h1 class="title is-size-2 is-size-4-mobile has-text-dark">
            {{ page.title }}
          </h1>
        </div>
        <ContentRenderer :value="page" />
      </section>
    </div>
  </NuxtLayout>
</template>
